import logo from './logo.svg'
import './App.css'
import Home from './Home'
import { Route, BrowserRouter as Router } from 'react-router-dom'

function App() {
  return (
    <Router>
      <Route path={['/:id', '/']}>
        <Home />
      </Route>
    </Router>
  )
}

export default App
